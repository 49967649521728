
import { defineComponent, computed, onBeforeMount, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AppButton from '@/components/atoms/AppButton.vue'
import { getDataBlob } from '@/helpers/util/webApiUtil'
import ja from 'date-fns/locale/ja'
import Datepicker from 'vue3-datepicker'

export default defineComponent({
  components: {
    AppButton,
    Datepicker,
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isRekion = route.meta.isRekion

    const isServer = typeof window === 'undefined'
    if (isServer) return

    const isLight = () => {
      const colorMode = isRekion ? 'is-light' : element[0].className
      return colorMode === 'is-light'
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const element = document.getElementsByTagName('html')!
    const color = isLight() ? ref('#000') : ref('#fff')
    const backgroundColor = isLight() ? ref('#fff') : ref('#5a5a5a')
    const observer = new MutationObserver(() => {
      color.value = isLight() ? '#000' : '#fff'
      backgroundColor.value = isLight() ? '#fff' : '#5a5a5a'
    })
    observer.observe(element[0], {
      attributes: true,
      attributeFilter: ['class'],
    })
    const canStatistiscDownload = computed(() => store.getters.canDownloadStatistics)
    const loginUserCardId = computed(() => store.getters.loggedInUser?.cardId)
    const fromDate = ref()
    const toDate = ref()

    const dateCssConfig = computed(() => {
      return {
        '--vdp-hover-bg-color': isRekion ? '#5b4d40' : '#005eae',
        '--vdp-selected-bg-color': isRekion ? '#5b4d40' : '#005eae',
        color: color.value,
        'background-color': backgroundColor.value,
      }
    })

    const convertDate = (date: Date) => {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      return `${year}-${month}`
    }

    onBeforeMount(() => {
      if (isRekion) {
        if (store.getters.isRekionAdmin) return
      } else {
        if (loginUserCardId.value === 'X00000001' || canStatistiscDownload.value) return
      }
      store.commit('NotLoggedIn', true)
      router.push({ path: '/' })
    })

    onMounted(() => {
      const today = new Date()
      fromDate.value = today
      toDate.value = today
    })

    return {
      fromDate,
      toDate,
      ja,
      dateCssConfig,

      async downloadStatisticsTSV () {
        const from = convertDate(fromDate.value)
        const copyTo = new Date(toDate.value)
        copyTo.setMonth(toDate.value.getMonth() + 1)
        const to = convertDate(copyTo)
        const apiEndPoint = isRekion ? 'statistics/rekion/export' : 'statistics/export'
        const url = `${process.env.VUE_APP_API_BASE_URL}/${apiEndPoint}?from=${from}-01T00:00:00.000&to=${to}-01T00:00:00.000`
        const response = await getDataBlob(url)
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(response.data)
          link.download = isRekion ? '歴史的音源参加館統計.tsv' : '図書館送信統計.tsv'
          link.click()
        }
      },
    }
  },
})
